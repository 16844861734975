.chooseAvatars {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    justify-content: space-between;
    width: 100%
}

.join-avatar {
    flex-direction: column;
    gap: 1.5rem;
    height: 100vh;

    @media screen and (min-width: 300px) and (max-width: 380px) {
        // flex-direction: row;
        height: auto;
        padding-bottom: 30px;
    }
}

.back-choose-avatar{
    top: 7px;
}