.total-pl {
    display: flex;
    justify-content: space-between;
    background: rgba(28, 67, 132, 1);
    border-radius: 56px;
    width: 100%;
    box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.portfolio-total-bg {
    background: rgba(255, 255, 255, 1);
    border-radius: 56px;
    padding-bottom: 4px;
    width: 100%;
}

.portfolio-total {
    color: rgba(19, 200, 91, 1);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2509803922);
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
}

.portfolio-red-total {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2509803922);
    color: #ED3432;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
}

.total-text {
    color: var(--text-white);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

.total-pl-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 14px 31px
}

.portfolio-container {
    height: 514px;
    padding: 0px;
    width: 450px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 350px;
    }
}

.por-prices-container {
    width: 100%;
    grid-template-columns: 1fr;
    height: 94%;
}

.timer-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.seconds-left {
    padding-top: 12px;
    font-size: 23px;
    font-weight: bold;
    line-height: 63px;
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    padding-right: 15px;
}

.portfolio-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.portfolio-text {
    padding-top: 0px;
}

.setup-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    line-height: 40px;
}

.seconds-left-trade {
    padding-top: 12px;
    font-size: 23px;
    font-weight: bold;
    line-height: 20px;
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    padding-right: 15px;
}

.portfolio-refresh-button {
    justify-content: end;
    padding-left: 45px;
}

.logout-porfolio {
    top: 10px !important;
}

.net-worth {
    width: 100%;
}

.net_worth_text {
    font-size: 18px;
    text-align: end;
    padding-right: 15px;
    padding-bottom: 3%;
    color: #fff !important;
}

.net_worth_amount {
    color: rgb(19, 200, 91);
}