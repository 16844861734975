.bottomsheet-container {
    backdrop-filter: blur(14px);
    background: #00000080;
    border-radius: 16px;
    padding: 8px 20px;
    // width: 38vh;
    margin-top: 15px;
    margin-bottom: 15px;
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
    margin-left: 10px;
    margin-right: 10px;
}

.bottomSheet {
    display: flex;
    justify-content: space-between;
    gap: 68px;
    align-items: center;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        gap: 28px;
    }
}

.bottomSheet-brok {
    display: flex;
    justify-content: space-between;
    gap: 68px;
    align-items: center;
}

.trade-btn {
    width: 40px;
    height: 40px;
}

.bottom-btns {
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.bottom-text {
    font-size: 12px;
    font-weight: 500;
    color: var(--text-white);

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        font-size: 10px;

    }
}

.history-bg {
    background-color: #244A88;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 22px;
        height: 22px;
        color: #fff;
    }
}

.history-linear-bg {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid transparent;
    background:  linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) padding-box,
    linear-gradient(0deg, #FFf -8.4%, #fff 88.28%)border-box !important;
    box-shadow: 0px 7px 8px 0px #00000040;
    border: 1px solid #fff;

    img {
        width: 22px;
        height: 22px;
        color: #fff;
    }
}