.back-button {
    padding-left: 1.6rem;
    position: absolute;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    cursor: pointer;
}

.heading-bckbtn-text {
    font-size: 34px;
    font-weight: bold;
    color: var(--text-white);
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    display: flex;
    align-items: start;
    position: relative;
    margin-top: -20px;

}

.colorHeading-bckbtn {
    font-size: 45px;
    font-weight: bold;
    line-height: 63px;
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    display: flex;
    align-items: start;
    position: relative;
    // margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    padding: 24px 0px;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        font-size: 23px;
        line-height: 63px;
    }

}

.colorHeading-backbtn {
    font-size: 34px;
    font-weight: bold;
    line-height: 63px;
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    display: flex;
    align-items: start;
    position: relative;
    margin-top: -20px;

    // display: flex;
    // align-items: center;
    // justify-content: center;
    // padding-top: 12px;
    @media screen and (min-width: 300px) and (max-width: 1200px) {
        font-size: 23px;
    }

}

.trade-back-button {
    padding-left: 1.6rem;
    position: absolute;
    top: 50px;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 1.2rem;
    cursor: pointer;
    width: -webkit-fill-available;
    padding-bottom: 12px;
}

.trade-colorHeading-backbtn {
    font-size: 23px;
    font-weight: bold;
    line-height: 63px;
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    display: flex;
    align-items: start;
    position: relative;
    margin-top: -20px;
    margin-left: 32px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // padding-top: 12px;

}