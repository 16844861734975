.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    // box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2509803922) inset;
    // border: 2px solid #3D70C9;
    // background: #1c4384;
    // color: #fff;
    border: 2px solid #fff;
    background: #ffffff66 !important;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2509803922) inset;
    border: 2px solid #fff;
    color: #fff;
    font-size: 14px;
    line-height: 20px !important;
}

.ant-select-single {
    height: 42px;
}

.ant-select-selector {
    line-height: 20px;
    font-weight: 700;
    color: var(--themeColor) !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #fff;
}

.ant-select .ant-select-arrow {
    color: #fff;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-weight: 500 !important;
    font-family: "Outfit", sans-serif;
    color: var(--themeColor);
}

.ant-select-single.ant-select-show-arrow .ant-select-open .ant-select-show-search {
    display: none !important;
}