.logout-btn {
    width: 20px;
    margin-right: 1rem;
    background: linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%);
    padding: 7px;
    border-radius: 8px;
}

.logout-container {
    // padding-left: 1.6rem;
    position: absolute;
    top: 50px;
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 1.2rem;
    cursor: pointer;
    z-index: 100;
    // width: 100%;
}

.logout-main_container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: end;
}