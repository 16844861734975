.input-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    color: var(--text-white);
    margin-bottom: 10px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        font-size: 12px;
    }
}

.search-input {
    box-shadow: 0px 0px 0px 2px #3D70C966;
    box-shadow: 0px 2px 1px 0px #00000040 inset;
    border: 2px solid #3D70C9;
    border-radius: 6px;
    background: #1c4384;
    height: 50px;
    width: -webkit-fill-available;
    padding-left: 12px;
    line-height: 20px;
    font-weight: 700;
    color: var(--text-white);

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        height: 42px;
    }
}

.input-instruct {
    display: flex;
    align-items: center;
    gap: 5px;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.get-help-add-on-hover {
    position: absolute;
    bottom: 31px;
    left: -121px;
    color: var(--button-text-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) border-box !important;
    background: #fff;
    padding: 6px 16px 6px 16px;
    border-radius: 18px;
    width: 215px;
    height: 16px;
    display: none;

    &:hover {
        background-color: #fff;
    }

    @media screen and (min-width: 350px) and (max-width: 550px) {
        left: -90px;
    }
}

.get-help-cash {
    width: 145px !important;
    left: -86px;

    @media screen and (min-width: 350px) and (max-width: 550px) {
        left: -63px;
    }
}

.get-help-add-on-hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border-top: 0px solid #fff;
    border-bottom: 1px solid #FF690C;
    border-left: 1px solid #FF690C;
    border-right: 0px solid #fff;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    margin-top: -4px;
    background-color: #fff;

    @media screen and (min-width: 350px) and (max-width: 550px) {
        left: 37%;
    }
}

.get-help-addOn-hover {
    position: relative;
    margin-top: -5px;
}

.get-help-addOn-hover:hover .get-help-add-on-hover {
    display: block;
}

.help-image-logo {
    cursor: pointer;
    color: #fff;
}

.get-help-addOn-hover:hover .get-help-code-hover {
    display: block;
}

.get-help-code-hover {
    position: absolute;
    bottom: 35px;
    left: -162px;
    color: var(--button-text-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) border-box !important;
    background: #fff;
    padding: 6px 16px 6px 16px;
    border-radius: 18px;
    width: 285px;
    height: 30px;
    display: none;

    &:hover {
        background-color: #fff;
    }

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: -90px;
    }
}

.get-help-code-hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 52%;
    border-top: 0px solid #fff;
    border-bottom: 1px solid #FF690C;
    border-left: 1px solid #FF690C;
    border-right: 0px solid #fff;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    margin-top: -4px;
    background-color: #fff;

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: 37%;
    }
}

.get-help-addOn-hover:hover .get-help-lobby-hover {
    display: block;
}

.get-help-lobby-hover {
    position: absolute;
    bottom: 35px;
    left: -214px;
    color: var(--button-text-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) border-box !important;
    background: #fff;
    padding: 6px 16px 6px 16px;
    border-radius: 18px;
    width: 245px;
    height: 30px;
    display: none;

    &:hover {
        background-color: #fff;
    }

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: -90px;
    }
}

.get-help-lobby-hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 81%;
    border-top: 0px solid #fff;
    border-bottom: 1px solid #FF690C;
    border-left: 1px solid #FF690C;
    border-right: 0px solid #fff;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    margin-top: -4px;
    background-color: #fff;

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: 37%;
    }
}

.get-help-addOn-hover:hover .get-help-response-hover {
    display: block;
}

.get-help-response-hover {
    position: absolute;
    bottom: 26px;
    left: -190px;
    color: var(--button-text-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) border-box !important;
    background: #fff;
    padding: 6px 16px 6px 16px;
    border-radius: 18px;
    width: 205px;
    height: 30px;
    display: none;

    &:hover {
        background-color: #fff;
    }

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: -90px;
    }
}

.get-help-response-hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 81%;
    border-top: 0px solid #fff;
    border-bottom: 1px solid #FF690C;
    border-left: 1px solid #FF690C;
    border-right: 0px solid #fff;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    margin-top: -4px;
    background-color: #fff;

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: 37%;
    }
}

.get-help-addOn-hover:hover .get-help-setup-hover {
    display: block;
}

.get-help-setup-hover {
    position: absolute;
    bottom: 35px;
    left: -200px;
    color: var(--button-text-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) border-box !important;
    background: #fff;
    padding: 6px 16px 6px 16px;
    border-radius: 18px;
    width: 285px;
    height: 30px;
    display: none;

    &:hover {
        background-color: #fff;
    }

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: -90px;
    }
}

.get-help-setup-hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 65%;
    border-top: 0px solid #fff;
    border-bottom: 1px solid #FF690C;
    border-left: 1px solid #FF690C;
    border-right: 0px solid #fff;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    margin-top: -4px;
    background-color: #fff;

    @media screen and (min-width: 350px) and (max-width: 550px) {
        // left: 37%;
    }
}

.h-code {
    height: 46px;
}

input {

    &:disabled {
        background-color: #1C4384 !important;
        color: #fff !important;
        opacity: 1;
        border: 2px solid #3D70C9 !important;
        box-shadow: 0px 2px 1px 0px #00000040 inset;
    }

    // &:disabled {
    //     background-color: #1C4384 !important
    // }
}