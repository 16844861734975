.leader-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.leader-prf-conatiner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 330px;
    height: 87px;
    box-shadow: 0px 0px 0px 2px #3D70C966;
    box-shadow: 0px 2px 1px 0px #00000040 inset;
    background: #1C4384;
    border-radius: 56px;
    padding: 0px 10px;
}

.leader-dimension {
    // width: 585px;
    height: auto;
    align-items: baseline;
    padding: 10px;
    overflow: auto;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        // width: 265px;
        // height: 420px;
    }
}

.leader-profile {
    width: 67px;
    height: 67px;
    // margin-top: 3px;
    border-radius: 50%;
}

.leader-width-button {
    width: 180px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 161px;
    }
}

.leader-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.leader-social-container {
    max-height: 234px;
    padding: 0px;
    width: 550px;
    height: 234px;
    overflow-y: auto;
    overflow-x: hidden;
    backdrop-filter: blur(44px);
    background: rgba(0, 0, 0, 0);
    color: #fff;
    margin: 4px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 320px;
    }
}

.social-logo {
    width: 33px;
    height: 33px;
}

.social-logo-container {
    background: #FFFFFFCC;
    border-radius: 50%;
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
}

.social-logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px 20px;
}

.social-text {
    font-family: 'Outfit';
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
}
.player-winner{
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
}
.player-winner-flex{
    display: flex;
    align-items: end;
    flex-direction: column;
    gap: 1px;
    padding-right: 5%;
}