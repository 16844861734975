.joinGame-dimension {
    width: 355px;
    height: 355px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 270px;
        height: 313px;
    }
}

.join-upper {
    flex-direction: column;
    gap: 1.5rem;
    height: 100vh;

    @media screen and (min-width: 300px) and (max-width: 380px) {
        // flex-direction: row;
        // height: auto;
        padding-bottom: 30px;
    }
}

.joinGame-container {
    flex-direction: column;
    gap: 2rem;
}

.investor-image {
    width: 82px;
    height: 82px;
    margin-bottom: -18px;
    z-index: 1;
}

.selc-profile {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 6px;
    bottom: 30px;
}

.response-heading {
    @media screen and (min-width: 300px) and (max-width: 380px) {
        line-height: 60px !important;
    }
}

.note-response {
    color: #FFC107;
    padding: 0px 8px;
    font-size: 11px;
}