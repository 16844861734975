.splash-container {
    width: 100%;
    height: 100vh;
    background-image: url('../../../assets/images/Web-login-BG.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        background-image: url('../../../assets/images/splash-bg-2.png');
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        overflow-y: hidden !important;
    }
}

.stock-bg-container {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../../assets/images/The-showdown.png');
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: auto;
    -webkit-user-drag: none;
    overflow-y: auto !important;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        background-image: url('../../../assets/images/splash-bg-7.png');
        overflow-y: auto !important;
    }

    @media screen and (min-width: 700px) and (max-width: 1200px) {
        background-image: url('../../../assets/images/splash-bg-7.png');
        // overflow-y: hidden !important;
        overflow-y: auto !important;

    }
}

.splash-height {
    height: 100vh;
}

.splash-bg {
    width: 50%;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 100%;
    }
}