.text-container-trade {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.buy-button {
    background: #13c85b;
    padding: 3px 10px;
    border-radius: 9px;
    cursor: pointer;
}

.text-buy {
    text-shadow: 0px 2px 2px 0px #00000073;
    font-size: 12px;
    line-height: 25px;
    font-weight: 600;
}

.sell-button {
    background: #ED3432;
    padding: 3px 8px;
    border-radius: 9px;
    cursor: pointer;
}

.trade-buy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    // height: 26vh;
}

.trade-flex {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.trade-total {
    box-shadow: 0px 0px 0px 2px #3D70C966;
    box-shadow: 0px 2px 1px 0px #00000040 inset;
    background: #1C4384;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-white);
    padding: 15px;
    width: 88%;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 85%;
    }
}

.trade-text-total {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
}

.trade-price-total {
    font-size: 22px;
    line-height: 20px;
    font-weight: 700;
}

.trade-input {
    padding: 0px 12px;
    border: 2px solid #1C4384;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #1C4384;
    border-radius: 12px;
    color: var(--text-white);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-shadow: 0px 2px 2px #00000040;
    width: auto;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 125px;
    }
}

.trade-input:hover {}

.trade-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    color: #1C4384;
    margin-bottom: 8px;
    padding-left: 15px;
}

.trade-container {
    max-height: 514px;
    padding: 0px;
    height: auto;
    width: 450px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 350px;
    }
}

.trade-container-selc {
    max-height: 514px;
    padding: 0px;
    width: auto;
    height: 385px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 350px;
    }
}

.disabled {
    background-color: #ffffff29;
    cursor: not-allowed;
}

.selldisabled {
    background-color: #c77372;
    cursor: not-allowed;
}

.text-buy-border {
    border: 2px solid transparent;
    background: linear-gradient(#13c85b, #13c85b) padding-box,
        linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) border-box !important;
    font-weight: 800;
}

.text-sell-border {
    border: 2px solid transparent;
    background: linear-gradient(#ED3432, #ED3432) padding-box,
        linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) border-box !important;
    font-weight: 800;
}

.buy-disabled {
    background-color: #ffffff29;
    cursor: not-allowed;
}