.login-container {
    // height: 100vh;
    position: relative;
    // overflow-y: hidden;
    gap: 5rem;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        flex-direction: column;
        gap: 0px;
    }

    // @media screen and (min-width: 300px) and (max-width: 400px) {
    //     padding-bottom: 25%;
    // }

}

.login-upper-container {
    position: relative;
    flex-direction: column;
}

.login-upper {
    position: absolute;
    top: -28px;
    width: 464px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        top: -18px;
        width: 356px;
    }

}

.login-text {
    position: absolute;
    z-index: 1;
    top: 12px;
}

.login-dimension {
    width: 360px;
    height: 360px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 260px;
        height: 300px;
    }

}

.register-dimension {
    width: 360px;
    height: 400px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 260px;
        height: 347px;
    }

}

.border-blue {
    border: 6px solid #2E5A9F;
    border-radius: 40px;
    box-shadow: 0px 4px 52px 0px #000000E5;
    box-shadow: 0px 14px 0px 0px #2A4563;
}

.border-dark-blue {
    border: 4px solid #001E4D;
    box-shadow: 0px 0px 0px 2px #3D70C9;
    border-radius: 38px;
}

.border-orange {
    border: 6px solid transparent;
    background: linear-gradient(#2c519f, #2c519f) padding-box,
        linear-gradient(180deg, #CA702C 0%, #DF8A40 100%) border-box !important;

    border-radius: 34px;
}

.border-yellow {
    border: 10px solid transparent;
    background: linear-gradient(#2c519f, #2c519f) padding-box,
        linear-gradient(180deg, #D6AD00 0%, #FDD116 100%) border-box !important;
    border-radius: 28px;
}

.border-inner-blue {
    border: 2px solid #3D70C9;
    box-shadow: 0px 2.3px 0px 3px #001E4D;
    border-radius: 20px;
}

.Phone-number-container {
    padding: 100px 25px 25px 20px;
    display: flex;
    flex-direction: column;
    gap: 2.1rem;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        padding: 72px 25px 25px 20px;
        gap: 1.6rem;

    }
}

.login-bg-img {
    width: 698px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 85%;
        // margin-top: -32%;
        height: 30%;
    }
}

.login-bg-profile {
    width: 698px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 80%;
        // margin-top: -32%;
        // height: 30%;
    }
}

.register-text {
    position: absolute;
    z-index: 1;
    top: 4px;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-left: 5px;
    padding-top: 8px;
    font-weight: 500
}

.text-footer-login {
    font-family: "Outfit", sans-serif;
    color: var(--text-white);
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 10px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        font-size: 13px;
        margin-top: 5px;

    }
}

img,
.no-context-menu,
div[style*="background-image"] {
    -webkit-touch-callout: none !important;
    /* Prevent the action sheet */
    -webkit-user-select: none !important;
    /* Disable text/image selection */
    user-select: none !important;
    /* For other browsers */
    /* Disables pointer interaction */
}

/* Disable dragging of the image */
img,
.no-context-menu,
div[style*="background-image"] {
    -webkit-user-drag: none !important;
    /* WebKit browsers */
    -moz-user-drag: none !important;
    /* Firefox */
    user-drag: none !important;
    /* Standard */
}