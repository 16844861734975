.account-heading {
    display: flex;
    position: relative;
}

.account-profile {
    width: 215px;
    height: 215px;
    border-radius: 50%;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 150px;
        height: 150px;
    }
}

.account-upper {
    gap: 2rem;
    margin-top: 25px;
}

.heading-bckbtn-acc-text {
    font-size: 34px;
    font-weight: bold;
    color: var(--text-white);
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    display: flex;
    align-items: start;
    position: relative;
    // margin-top: -20px;
    padding-top: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.updateProfile-btn{
    padding-top: 12px;
}