.otp-container {
    display: flex;
    flex-direction: column;
}

.verify-input {
    text-align: center;
    padding: 0px;
    width: 63px;
    border: 2px solid #1C4384;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: var(--gradient);
    border-radius: 12px;
    color: #1C4384;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    text-shadow: 0px 2px 2px #00000040;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 42px;
    }
}

.otp-container-input {
    display: flex;
    gap: 1rem;
}

.resend-container {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    color: var(--text-white);
    margin-top: 12px;
    text-align: center;
}

.verify-phone-container {
    padding: 100px 25px 25px 20px;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        padding: 70px 25px 25px 20px;
        gap: 1.1rem;
    }
}