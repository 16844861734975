.portContainer {
    flex-direction: column;
    gap: 3rem;
}

.portfolio-dimension {
    width: 565px;
    max-height: 300px;
    height: 210px;
    align-items: flex-start;
    margin: 15px 0px;
    overflow: auto;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 265px;
    }
}

.portfolio-showdown-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    overflow: auto;
    padding-right: 10px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
}

.Portfolio-btn {
    width: 160px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 105px;
    }
}

.end-game-container {
    @media screen and (min-width: 1200px)  {
        flex-direction: column;
    }
}