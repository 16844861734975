.setting-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    color: var(--text-white);
    width: 100%;
    padding-left: 15px;
}

.justify-setup {
    justify-content: space-around;
}
.setting-game{
    display: flex;
    gap: 10px;
}
.right-arrow{
    width: 15px;
}