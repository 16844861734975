.stock-dimension {
    width: 585px;
    height: 352px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 265px;
        height: 457px;
    }
}

.role-dimension {
    width: 585px;
    height: 352px;
    align-items: baseline;
    padding-top: 21px;
    overflow: auto;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 265px;
        height: 420px;
    }
}

.prices-container {
    height: 62%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    overflow: auto;
    padding: 0px 10px;
    margin: 15px 0px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        grid-template-columns: 1fr;
        height: 94%;
    }
}

.company-profile {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    // margin-top: 3px;
}

.pricetext-container {
    font-weight: 600;
    line-height: 20px;
    color: var(--text-white);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 13px;
    text-shadow: 0px 3px 3px #00000040;
}

.pricetext-name {
    font-size: 19px;
}

.pricetext-subname {
    font-size: 13px;
}

.company-height {
    height: 58px;
    padding-left: 7px;
    gap: 15px;
}

.stock-prices-btns {
    display: flex;
    gap: 12px;
}

.stockContainer {
    flex-direction: column;
    // padding-top: 6rem;
}

.containerss {
    height: 100vh;
    display: flex;
    flex-direction: column;

}

.stocks-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    align-items: center;
    // height: 80vh;

    @media screen and (min-width: 300px) and (max-width: 380px) {
        // height: auto;
        // justify-content: space-around;
    }

    @media screen and (min-width: 380px) and (max-width: 700px) {
        // height: 100vh;
        // justify-content: space-around;
    }

    @media screen and (min-width: 700px) and (max-width: 1200px) {
        // height: 90vh;
        // justify-content: space-around;
    }
}

.stock-setup-container {
    gap: 2rem;
    position: relative;
    // overflow: auto;
}

.trade-width-button {
    width: 180px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 100px;
    }
}

.market-width-button {
    width: 225px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 225px;
    }
}

.market-btn {
    padding-bottom: 5%;
}

.help-btn {
    position: absolute;
    top: -0px;
    right: 10px;
    cursor: pointer;
    z-index: 1000;
}

.help-trade-btn {
    top: -63px;
    right: 9px
}

.transaction-help-btn {
    top: -75px;
    right: 9px
}