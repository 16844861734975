.main-heading {
    font-size: 85px;
    font-weight: 400;
    line-height: 103px;
    color: #FFC107;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    padding: 10px;
    position: relative;
    display: flex;
    align-items: start;
    position: relative;
    margin-top: -20px;
    text-align: center;
    // text-shadow: 0px 8px 19px #000000A6;

    // background: linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%);
    // background-clip: text;
    // -webkit-background-clip: text;
    // color: transparent;
    // -webkit-text-fill-color: transparent;
    @media screen and (min-width: 381px) and (max-width: 700px) {
        font-size: 50px;
        line-height: 63px;
    }

    @media screen and (min-width: 300px) and (max-width: 380px) {
        font-size: 53px;
        line-height: 44px;
        margin-top: 20px;
    }

}

.market-heading {
    font-size: 40px;
    font-weight: 500;
    line-height: 64px;
    padding-left: 44px;

    @media screen and (min-width: 300px) and (max-width: 380px) {
        margin-top: -18px;
        font-size: 30px;
    }

    @media screen and (min-width: 300px) and (max-width: 700px) {
        font-size: 30px;
    }
}

.showDown-dimension {
    width: 280px;
    height: 300px;
    flex-direction: column;
}

.showDown-upper {
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        // flex-direction: row;
    }
}

.referral-input {
    text-align: center;
    padding: 0px;
    width: 32px;
    height: 32px;
    border: 2px solid #1C4384;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: var(--gradient);
    border-radius: 12px;
    color: #1C4384;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    text-shadow: 0px 2px 2px #00000040;
}

.code-container-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.code-label {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    color: var(--text-white);
    padding: 0px 8px;
    padding-bottom: 10px;
}

.showDown-container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 0px 12px;
}

.continue-btn {
    padding: 0px 12px;
}

.showDown-heading {
    @media screen and (min-width: 300px) and (max-width: 380px) {
        margin-top: 0px !important;
    }
}

.showdown-image {
    width: 85%;
    height: auto;
}