.spin-stand {
  width: 182px;
  height: 42px;
  position: absolute;
  bottom: -0.2rem;

}

.spin-top {
  width: 32px;
  position: absolute;
  top: 2.3rem;
  z-index: 1;
}

.spinner {
  transition: transform 2s cubic-bezier(0.42, 0, 0.58, 1);
  width: 100%;
  height: 100%;
}

.spinner-image {
  width: 100%;
  height: 100%;
}

.spinning {
  // animation: spin 15s linear forwards;
  transform-origin: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(3600deg);
  }
}

.spin-button {
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: absolute;
  top: 10rem;

  @media screen and (min-width: 300px) and (max-width: 380px) {
    top: 9rem;
  }

}

.spinner-names {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.name-box {
  position: absolute;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  width: 80px;
  font-family: "Baloo 2", sans-serif;
  text-shadow: 0px 3px 4px #00000040;
}

.box2_0 {
  top: 28%;
  left: 50%;
  transform: translate(-50%, 0);
}

.box2_1 {
  top: 66%;
  left: 51%;
  transform: translate(-50%, 0);
}

.box2_2 {
  top: 47%;
  left: 71%;
  transform: translate(-50%, 0);
}

.box3_0 {
  top: 64%;
  left: 40%;
  transform: translate(-50%, 0);
}

.box3_1 {
  top: 29%;
  left: 40%;
  transform: translate(-50%, 0);
}

.box3_2 {
  top: 47%;
  left: 71%;
  transform: translate(-50%, 0);
}

.box4_0 {
  top: 63%;
  left: 34%;
  transform: translate(-50%, 0);
}

.box4_1 {
  top: 31%;
  left: 34%;
  transform: translate(-50%, 0);
}

.box4_2 {
  top: 31%;
  left: 65%;
  transform: translate(-50%, 0);
}

.box4_3 {
  top: 63%;
  left: 67%;
  transform: translate(-50%, 0);
}

.box5_0 {
  top: 56%;
  left: 29%;
  transform: translate(-50%, 0);
}

.box5_1 {
  top: 30%;
  left: 35%;
  transform: translate(-50%, 0);
}

.box5_2 {
  top: 29%;
  left: 62%;
  transform: translate(-50%, 0);
}

.box5_3 {
  top: 53%;
  left: 72%;
  transform: translate(-50%, 0);
}

.box5_4 {
  top: 69%;
  left: 51%;
  transform: translate(-50%, 0);
}

.box-0 {
  top: 24%;
  left: 50%;
  transform: translate(-50%, 0);
}

.box-1 {
  top: 36%;
  left: 71%;
  transform: translate(-50%, 0);
}

.box-2 {
  top: 59%;
  left: 71%;
  transform: translate(-50%, 0);
}

.box-3 {
  top: 75%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.box-4 {
  top: 59%;
  left: 29%;
  transform: translate(-50%, 0);
}

.box-5 {
  top: 36%;
  left: 30%;
  transform: translate(-50%, 0);
}

.spinner-logo {
  width: 60px;
  height: 60px;
}

.empty-player-text {
  font-size: 32px;
  font-weight: 400;
  line-height: 33px;
  text-align: start;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 2px #00000040;
  font-family: 'Bagel Fat One', sans-serif;
  text-shadow:
    2px 2px 0 #172A53,
    -2px -2px 0 #172A53,
    2px -2px 0 #172A53,
    -2px 2px 0 #172A53;
  padding: 7rem 15px;
}