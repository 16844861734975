.profile-dimension {
    width: 353px;
    height: 545px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 260px;
        height: 411px;
    }
}

.profile-container {
    padding-top: 5rem;
    flex-direction: column;
    gap: 1.2rem;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        padding-top: 4rem;
    }
}

.investor-profile {
    width: 171px;
    height: 171px;
    border-radius: 50%;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 98px;
        height: 98px;
    }
}

.profile-btn-container {
    flex-direction: column;
    gap: 1.8rem;
}

.profile-width-button {
    width: 290px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 205px;
    }
}

.buttons-profile {
    flex-direction: column;
    gap: 35px;
}

.profile-heading {
    font-size: 19px;
    font-weight: 400;
    line-height: 33px;
    text-align: start;
    color: #fff;
    text-shadow: 0px 2px 2px #00000040;
    font-family: 'Bagel Fat One', sans-serif;
    text-shadow:
        2px 2px 0 #172A53,
        -2px -2px 0 #172A53,
        2px -2px 0 #172A53,
        -2px 2px 0 #172A53;
}

.profile-landing-heading {
    font-size: 32px;
    line-height: 41px;
    text-align: center;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        font-size: 21px;
        line-height: 31px;

    }
}

.profile-heading-welcome {
    font-size: 19px;
}

.profile-flex {
    gap: 10px;
    padding: 28px 3px 32px 3px;
}

.btn-landing {
    background: #0D2E74;
    color: #fff !important;

    .button-text {
        color: #fff !important;
    }
}