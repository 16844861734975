.save-container {
  // box-shadow: 0px 6px 12px #0000001a;
  animation-name: grow-box;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  z-index: 100000;

  @media screen and (min-width: 300px) and (max-width: 550px) {
    width: auto;
    height: auto;
  }
}

.image_close {
  width: 19.5px;
  height: 19.5px;
  cursor: pointer;
}

.save-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: right;
  color: #FFF;
  padding: 15px;
  border-bottom: 1px solid #f4f6f6;
  width: 100%;

  @media screen and (min-width: 300px) and (max-width: 550px) {
    padding: 21px;
  }

  .close-button {
    cursor: pointer;
  }
}

.save-changes {
  background-color: #1C4384;
  padding: 13px 45px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 33px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    background: #1C4384
  }

  @media screen and (min-width: 300px) and (max-width: 550px) {
    font-size: 12px;
  }
}

.confirm-continue-btn {
  background-color: #fff;
  padding: 13px 45px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 33px;
  border: 1px solid #ef4444;
  color: #ef4444;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    border: 2.5px solid #ef4444;
  }
}

.save-continue-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: 300px) and (max-width: 550px) {
    flex-direction: row;
  }
}

.save-main-container {
  margin: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;

  @media screen and (min-width: 300px) and (max-width: 750px) {
    padding: 60px 0px;
    display: -webkit-flex
  }

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #1C4384;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (min-width: 300px) and (max-width: 550px) {
      font-size: 20px;
      margin-bottom: 26px;
      text-align: center;
    }
  }
}

.ant-modal-footer {
  display: none;
}

// .ant-modal-content {
//   background: none;
//   box-shadow: none;
//   border-radius: none;
//   .ant-modal-close {
//     display: none;
//   }
// }