.market-container {
    max-height: 450px;
    padding: 0px;
    width: 420px;
    height: 415px;
    overflow-y: auto;
    overflow-x: hidden;
    backdrop-filter: blur(44px);
    background: rgba(0, 0, 0, 0);
    margin: 4px;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        width: 320px;
    }
}



.market-dimension {
    width: 80%;
    height: 360px;
    flex-direction: column;
    justify-content: start;
    gap: 2rem;
    align-items: stretch;
    padding: 15px;

    .ant-select-selector {

        border-color: #fff !important;

    }

    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2509803922) inset;
        border: 2px solid #fff;
        background: #ffffff66 !important;
        color: #fff;
        border-radius: 10px;
        font-size: 14px;
        line-height: 20px !important;
    }

    @media screen and (min-width: 300px) and (max-width: 700px) {
        width: 250px;
    }
}

.market-mayhem-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -100px;
    height: 100vh;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        height: auto;
        margin-top: auto
    }
}

.market_container {
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: space-between;
    gap: 12px;
    font-weight: 600;
    font-size: 18px;
}

.market_container_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}

.ant-select-multiple .ant-select-selection-overflow-item {
    // background-color: var(--themeColor);
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    background-color: var(--themeColor);
    margin: 5px;
    color: #fff;
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove {
    color: #fff;
}

.back-btn {
    color: #fff;
    display: flex;
    align-items: start;
    justify-content: start;
    width: 100%;
}

.top-back-btn {
    top: 60px;
}