.game-dimension {
    width: 325px;
    height: 400px;
    flex-direction: column;
    gap: 1.5rem;
    align-items: stretch;
    padding: 15px;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        width: 250px;
        height: 360px;
    }
}

.setup-container {
    gap: 5rem;
    position: relative;
    overflow: hidden;
}

.game-setup-container {
    gap: 3rem;
    position: relative;
    overflow: hidden;
}


.overflow {
    overflow: visible
}

.margin-bottom {
    margin-bottom: 2%;
}

.back-setup {
    top: 26px !important;
}

.logout-profile {
    top: 30px;
}