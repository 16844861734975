.lobby-dimension {
    width: 640px;
    height: 349px;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 270px;
        height: auto;
        max-height: 530px;
        padding: 5% 0%;
    }
}

.lobby-label {
    font-size: 27px;
    line-height: 34px;
    font-weight: 600;
    text-align: center;
    color: var(--text-white);
    padding: 0px 20px;
    padding-bottom: 10px;
}

.text-container {
    font-weight: 600;
    line-height: 20px;
    color: var(--text-white);
}

.text-name {
    font-size: 17px;
}

.text-subname {
    font-size: 12px;
}

.text-subname-leader {
    font-size: 14px;
}

.player-profile {
    width: 41px;
    height: 41px;
    margin-top: 3px;
    border-radius: 50%;
}

.player-profile-bg {
    width: 41px;
    height: 41px;
    // margin-top: 3px;
    background: linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bagel Fat One', sans-serif;
    color: #1c4384;
}

.player-profile-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.player-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    height: 35%;
    overflow: auto;
    padding-right: 10px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        grid-template-columns: 1fr;
        height: auto;
        max-height: 50%;
    }
}

.player-prf-conatiner {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 225px;
    height: 52px;
    box-shadow: 0px 0px 0px 2px #3D70C966;
    box-shadow: 0px 2px 1px 0px #00000040 inset;
    background: #1C4384;
    border-radius: 56px;
    padding: 0px 5px;
}

.player-prf-conatiner-bg {
    background: #1c43849c;
}

.lobby-btn-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.lobby-btn-main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8em;
}

.start-button {
    width: 290px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 105px;
    }
}

.refresh-button {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    size: 16px;
    padding-top: 5px;
}

.refresh-button-trade {
    position: absolute;
    top: 3px;
    right: 45vw;
    size: 16px;
}

.cross-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.player-cross-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-right: 5px;
}