.avatar-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 80px;
        height: 80px;
    }
}

.ant-modal-content {
    background: linear-gradient(0deg, #FF690C -8.4%, #FFF708 88.28%) !important;
}

.ant-modal {
    padding-bottom: 0px !important;
}

.ant-modal .ant-modal-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    color: white;
    font-family: 'Bagel Fat One', sans-serif;
    text-shadow: 0px 3px 4px #00000040;
    text-shadow:
        2px 2px 0 #000000,
        -2px -2px 0 #000000,
        2px -2px 0 #000000,
        -2px 2px 0 #000000;
    letter-spacing: 1px;
    background: #FFF708 !important;
}