.green-arrow {
    width: 25px;
}

.text-percent {
    text-shadow: 0px 4px 4px #00000040;
    color: #13C85B;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
}

.text-red-percent {
    text-shadow: 0px 4px 4px #00000040;
    color: #ED3432;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
}

.text-container-transc {
    text-align: right;
    width: 32%;
    align-items: right;
    justify-content: right;
}

.transc-container {
    max-height: 585px;
    padding: 0px;
    width: 450px;
    height: 514px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 350px;

    }
}
.text-transc{
    width: 30%;
}