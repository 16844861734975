.landing-description {
    font-size: 23px;
    font-weight: 400;
    line-height: 38px;
    text-align: start;
    color: #fff;
    text-shadow: 0px 2px 2px #00000040;
    font-family: 'Bagel Fat One', sans-serif;
    text-shadow:
        2px 2px 0 #172A53,
        -2px -2px 0 #172A53,
        2px -2px 0 #172A53,
        -2px 2px 0 #172A53;
    display: flex;
    align-items: start;
    gap: 10px;
    padding: 2px 20px;
    margin-bottom: 20px;

    img {
        margin-top: 12px;

        @media screen and (min-width: 300px) and (max-width: 1200px) {
            margin-top: 4px;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        font-size: 16px;
        line-height: 23px;
        justify-content: center;
    }

}

.landing-profile {
    width: 171px;
    height: 171px;
    border-radius: 50%;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 64px;
        height: 64px;
    }
}

.landing-flex {
    gap: 10px;
    padding: 2px 3px 32px 10px;
}

.back-landing {
    top: 0px;
}

.landing-bg-profile {
    width: 698px;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        width: 80%;
        // margin-top: -32%;
        // height: 30%;
    }

    @media screen and (min-width: 700px) and (max-width: 1200px) {
        width: 60%;
        // margin-top: -32%;
        // height: 30%;
    }
}