.account-border {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 33px;
}

.account-container {
    width: 348px;
    height: 304px;
    background: #FFFFFF73;
    backdrop-filter: blur(44px);
    margin: 3px;
    border-radius: 28px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    padding: 1rem 2.4rem;
    flex-direction: column;
    gap: 1rem;

   @media screen and (min-width: 300px) and (max-width: 1200px) {        width: 270px;
        height: 262px;
    }
}

.empty-container {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #000080;
}