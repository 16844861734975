.keypad-container {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 12px;
}

.keypad {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
}

.centered-buttons {
    grid-column: span 3;
    /* Make the 0 and 10 buttons span across all columns */
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.keypad-symbol {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.button {
    width: 54px;
    height: 54px;
    background: var(--gradient);
    color: #173678;
    text-shadow: 0px 2px 3px #00000040;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    border: none;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Outfit', sans-serif;
}

.button-keypad {
    background: white;
    border-radius: 56px;
    padding-bottom: 3px;
    // width: 100%;
}

.operatorButton {
    background: #1C4384;
    color: #fff;
}

.updates-dimension {
    width: 250px;
    max-height: 300px;
    height: 200px;
    align-items: end;
    margin: 15px 0px;
    padding: 0px 5px;
    overflow: hidden;
}

.priceContainer {
    display: flex;
    // align-items: center;
    gap: 1.5rem;
    font-weight: 700;
    line-height: 30px;
    text-align: right;
    color: var(--text-white);
    font-size: 15px;
}

.priceValue {
    font-size: 45px;
}

.company-price-logo {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.price-vi {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.price-input {
    padding: 0px 12px;
    border: 2px solid #1C4384;
    background: #1C4384;
    border-radius: 56px;
    color: var(--text-white);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    box-shadow: 0px 0px 0px 2px #3D70C966;
    text-shadow: 0px 2px 1px #00000040 inset;
    text-align: right;
}

.priceMainContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.padding-bottom {
    padding-bottom: 8%;

    @media screen and (min-width: 300px) and (max-width: 400px) {
        padding-bottom: 25%;
    }
}