.portfolio-height {
    height: 67px;
    padding-left: 7px;
    gap: 15px;
    border-radius: 16px;
    align-items: center;
    width: 93%;
    margin-left: 7px;
}

.text-name-red {
    font-size: 17px;
    color: #ED3432;
}

.text-name-green {
    color: rgba(19, 200, 91, 1);
    font-size: 17px;
}