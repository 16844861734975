:root {
  --themeColor: #0d2e74;
  --blue-background: #1c4384;
  --drop-shadow: 0px 0px 0px 2px #3d70c966;
  --inner-shadow: 0px 2px 1px 0px #00000040 inset;
  --button-text-color: #173678;
  --gradient: linear-gradient(0deg, #ff690c -8.4%, #fff708 88.28%);
  --text-white: #fff;
  --bottom-color: #00000080;
  --total-color: #13c85b;
  --red-color: #ed3432;
  --text-pink: #e6568a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}