.help-container {
    position: absolute;
    z-index: 1000;
    top: 34px;
    left: 127px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        top: 28px;
        left: -15px;
    }
}

.help-dimension {
    width: 165px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 14px;
    color: #fff;
    gap: 14px;
}

.border-background-yellow {
    // border: 10px solid transparent;
    background: linear-gradient(#2c519f, #2c519f) padding-box,
        linear-gradient(180deg, #D6AD00 0%, #FDD116 100%) border-box !important;
    // border-radius: 28px;
    border: 2px solid #3D70C9;
    box-shadow: 0px 2.3px 0px 3px #001E4D;
    border-radius: 20px;
}

.pdf-text {
    color: #fff;
    text-decoration: none;
}

.help-container-transc {
    position: absolute;
    z-index: 1000;
    top: -42px;
    left: 40px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        top: -48px;
        left: -15px;
        z-index: 100;
    }
}

.help-container-trade {
    position: absolute;
    z-index: 1000;
    top: -32px;
    left: 35px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        top: -35px;
        left: -15px;
        z-index: 100;
    }
}

.help-conatienr-text {
    font-weight: 600;
    line-height: 20px;
    color: var(--text-white);
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
    padding-right: 13px;
    text-shadow: 0px 3px 3px #00000040;
}