.splash-end-container {
    display: flex;
    gap: 15px;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        flex-direction: column;
        gap: 25px;
    }
}

.splash-width-button {
    width: 290px;

    @media screen and (min-width: 300px) and (max-width: 1200px) {
        width: 205px;
    }
}

.end-container {
    display: flex;
    align-items: end;
    justify-content: center;
}

.splash-end {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
}

.splash-bg-end {
    width: 80%;

    @media screen and (min-width: 300px) and (max-width: 700px) {
        width: 100%;
    }
}